import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import "github-markdown-css"
import "../style.scss"

export default function Template({ data }) {
  const { markdownRemark } = data
  const { html } = markdownRemark
  return (
    <Layout>
      <div className="section pt-1 pb-1 markdown-body is-size-7">
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
    }
  }
`